$spacing: 1rem;
$invalidColor: #DB4857;

.checkout-form {
  .payment-methods {
    margin: 0 $spacing * 2;
    padding: 2 * $spacing 0;
  }
  a, input, textarea, button {
    &, &:-webkit-autofill {
      font-size: inherit;
      font-family: inherit;
      color: inherit;
    }
  }
  a, button {
    text-transform: inherit;
  }
  a {
    text-decoration: none;
  }

  .form {
    .form-input {
      &.is-invalid {
        .selection {
          border-bottom: 1px solid $invalidColor;
        }
        input {
          &:not(.search) {
            border-bottom: 1px solid $invalidColor;
          }
        }
      }
    }
  }

  .text-input input, .dropdown-input input, .dropdown-input .dropdown > .text, .label, label {
    &:not(.button) {
      padding: 0.2rem 0.4rem;
    }
  }
  .text-input input, .dropdown-input input {
    background-color: transparent;
  }
  .button, label, .label {
    font-size: 0.75rem;
    //text-transform: uppercase;
    letter-spacing: 1px;
  }
  .label, label {
    &:not(.button) {
      margin-bottom: $spacing / 2;
      color: #333;
    }
  }
  .text-input, .dropdown-input {
    width: 15rem;
  }
  .text-input input, .selection {
    border: none;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
    //background-color: white;
  }
  .dropdown-input .dropdown {
    position: relative;

    &.search {
      input {
        border: none;
        width: calc(100% - 2rem); //TIP account for dropdown arrow
      }
      & > .text {
        position: absolute;
        top: 0;
      }
    }

    .dropdown.icon {
      position: absolute;
      top: 0.4rem;
      right: 0.3rem;
      opacity: 0.2;
    }
    .menu {
      background-color: white;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
      // position: relative;
      top: calc(100% - 1px);
      max-height: 10rem;
      overflow: auto;
      //left: -$menuSpacing;
      //width: calc(100% + #{$menuSpacing * 2});
      z-index: 1000;

      .item .text, .message {
        padding: 0.4rem;

        &:empty {
          min-height: 30.16px;
        }
      }

      .item {
        color: #444;

        &:hover {
          background-color: #f9f9f9;
        }
        &.active {
          background-color: #eee;
          //font-weight: bold;
          color: black;
        }
      }
    }
  }
  .button {
    // margin-left: auto;
    background-color: #000;
    color: white;
    border-radius: 0;

    &:hover {
      opacity: 0.8;
    }
  }

}
